@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.license-input-container {
    display: flex;
    flex-wrap: wrap;
    order: 0;
    flex-grow: 10;
    margin-right: 0;
    margin-bottom: -1rem;

    >*:not(:empty) {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    .input-filter {
        order: 0;
        flex-grow: 6;
        width: auto;
        max-width: 728px;
    }
    
    .select-filter {
        max-width: 150px;
        flex-grow: 2;
        width: auto;
    }
}

.filtering-results {
    margin-left: 1rem;
    line-height: 3rem;
    color: awsui.$color-text-form-default;
}